<template>

  <auth-content
    :forceShowSidebarToggleButton="hasAccessToContent"
  >
    <el-header :title="$t('modules.wiki.title')" slot="header" :favorites="false">
      <div slot="left">
        <el-header-back />
      </div>

      <div slot="actions" v-if="hasAccessToContent || content">
        <el-header-action classes="btn-secondary" size="xs" icon="copy3" :label="$t('modules.wiki.content.actions.clone')" @dblclick.native="cloneContent()" v-tooltip.left="$t('common.dbl_click_clone')" />
        <el-header-action classes="btn-secondary" v-if="content.has_access.length > 1" size="xs" icon="mail-read" :label="$t('modules.wiki.content.actions.send')" @click.native="openModal('send-wiki-content')"/>

        <el-header-action
          size="xs"
          @click.native="remove_content_confirm = false; openModal('wiki-content-remove')"
          class="btn-danger"
          v-if="isCurrentWorldOwner || content.user_id == currentUser.id"
          icon="trash"
        />
      </div>

    </el-header>

    <div v-if="hasAccessToContent === false">
      <module-entity-need-access
        :entityId="parseInt($route.params.content)"
        entityModule="wiki"
        entityType="Content"
      />
    </div>

    <div v-if="socketConnected && hasAccessToContent && content">

      <el-alert type="info" v-if="showStepsBlock">
        <p>
          {{ $t('modules.wiki.content.steps_block.help_text', {contentTitle: content.name}) }}
        </p>
        <div class="container">
          <div class="row bg-white rounded shadow-sm mb-1 text-center">
            <div class="bghover-light border-right col-md-6 col-lg-3 flex-fill p-2 cursor-pointer" @click.prevent="openModal('wiki-content-description')">
              <div><div class="mb-1 font-weight-bold badge badge-pill badge-info">1</div></div>
              {{ $t('modules.wiki.content.steps_block.read_guide') }}
            </div>
            <div class="bghover-light border-right col-md-6 col-lg-3 flex-fill p-2 cursor-pointer" @click="highlightContent()">
              <div><div class="mb-1 font-weight-bold badge badge-pill badge-info">2</div></div>
              {{ $t('modules.wiki.content.steps_block.edit_content') }}
            </div>
            <div class="bghover-light border-right col-md-6 col-lg-3 flex-fill p-2 cursor-pointer" @click="highlightAccess()">
              <div><div class="mb-1 font-weight-bold badge badge-pill badge-info">3</div></div>
              {{ $t('modules.wiki.content.steps_block.give_access') }}
            </div>
            <router-link tag="div" class="bghover-light col-md-6 col-lg-3 flex-fill p-2 cursor-pointer" :to="{name: 'quest.index', params: { world: $route.params.world, openCardOnLoad: true }}">
              <div><div class="mb-1 font-weight-bold badge badge-pill badge-info">4</div></div>
              {{ $t('modules.wiki.content.steps_block.throw_card') }}
            </router-link>
          </div>
        </div>
      </el-alert>

      <el-alert v-if="isContentLocked" type="warning" :html="$t('modules.wiki.tokens.locked.description', { name: content.locked_by.full_name })" />
      <el-alert v-if="erasedContent != null && isContentLocked" type="danger">
        <div v-if="erasedContent != null">
          {{ $t('modules.wiki.tokens.locked.erased') }}
          <div class="editor__content form-control mt-2" rows="10" v-html="erasedContent"></div>
        </div>
      </el-alert>

      <el-alert v-if="content.mentions && content.mentions.empty_tokens && hasEmptyTokens && Object.keys(content.mentions.empty_tokens).length > 0 && !isContentLocked" type="secondary tokens" :title=" $t('modules.wiki.tokens.bloc.title')" :html="$t('modules.wiki.tokens.bloc.description')">
        <form @submit.prevent="createTokens()">
          <div class="mt-2 empty-tokens-forms">
            <div class="form-row align-items-center mt-2" v-for="empty_token in content.mentions.empty_tokens" v-if="!tokenExist(empty_token)">
              <div class="col-6 font-weight-bold">
                {{ empty_token }}
              </div>
              <div class="col-6">
                  <input required :ref="'empty-token-' + empty_token" type="text" class="form-control" :placeholder="empty_token">
              </div>
            </div>
          </div>
          <button :disabled="loadingToken" type="submit" class="btn btn-success btn-sm my-1">
            <span v-if="!loadingToken">{{ $t('common.save') }}</span>
            <loader v-else />
          </button>
        </form>
      </el-alert>

      <div class="mb-3 pl-2 border-left border-dark" v-if="Object.keys(usersConnected).length > 1">
        <div class="font-weight-bold mb-1"><i class="icon-primitive-dot text-success"/> {{ $tc('modules.wiki.content.users_connected', Object.keys(usersConnected).length ) }}</div>
        <span v-for="user, socketID in usersConnected" :key="socketID" class="">
          <span v-if="usersMentionsById[user.id]" class="align-items-center mr-2 small">
            <el-avatar size="xsmall" :src="usersMentionsById[user.id].avatar.thumb"/>
            <span class="ml-1">{{ usersMentionsById[user.id].full_name }}</span>
          </span>
        </span>
      </div>

      <div :class="{'highlighted': contentIsHighlighted}">
        <div class="card">
          <div class="card-content-inner">
            <div class="card-header d-md-flex justify-content-between align-items-start">
              <div class="m-0 display-title">
                <span
                  v-if="!isContentLocked"
                  class="m-0 pr-2"
                  contenteditable
                  @focus="trimItself"
                  @blur="updateTitle"
                  @keyup.enter="$event.preventDefault(); $event.target.blur()"
                >
                  {{ content.name }}
                </span>
                <span
                  v-else
                  class="m-0 pr-2"
                >
                  {{ content.name }}
                </span>
              </div>
              <div class="mt-2 mt-md-0 ml-md-2 badge badge-lg badge-light dropdown" data-toggle="dropdown">
                <span v-if="!loadingCategory" class="dropdown-toggle cursor-pointer" @click="categories ? '' : loadCategories()">
                  <span v-if="content.category" v-html="content.category.world_id === null ? $t('modules.quest.categories.' + content.category.name) : content.category.name">
                  </span>
                  <span v-else>
                    {{ $t('modules.wiki.categories.empty') }}
                  </span>
                </span>
                <span v-else>
                  <loader />
                </span>
                <div class="dropdown-menu dropdown-menu-right bg-light shadow">
                  <div v-if="!categories" class="text-center p-2"><loader /></div>
                  <div v-else-if="Object.keys(categories).length <= 1" class="p-2">
                    <small class="font-italic text-grey">
                      {{ $t('modules.wiki.categories.messages.empty') }}
                    </small></div>
                  <div v-else class="small">
                    <span v-for="(category) in categories" :key="category.id">
                      <a @click.prevent="content.category_id = category.id; content.category = category" v-if="category.id != content.category_id" href="#" class="dropdown-item" v-html="category.world_id === null ? $t('modules.quest.categories.' + category.name) : category.name"></a>
                    </span>
                  </div>
                  <!-- <a href="#" class="dropdown-item"><i class="icon-user-lock"></i> Account security</a> -->
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="editor">
                <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
                  <div class="menubar toolbar-table-wrapper text-center">
                    <div class="toolbar">
                      <div class="toolbar-table rounded shadow px-3 py-2" v-if="isActive.table()">

                        <div class="pb-1">
                          <span class="font-weight-bold pr-2">{{ $t('common.editor.table.col') }}</span>
                          <button
                            class="menubar__button small"
                            @click="commands.addColumnBefore"
                          >
                            {{ $t('common.editor.table.add_col_before') }}
                          </button>
                          <button
                            class="menubar__button small"
                            @click="commands.addColumnAfter"
                          >
                            {{ $t('common.editor.table.add_col_after') }}
                          </button>
                          <button
                            class="menubar__button small"
                            @click="commands.deleteColumn"
                          >
                            {{ $t('common.editor.table.delete_col') }}
                          </button>
                        </div>
                        <div class="pb-1">
                          <span class="font-weight-bold pr-2">{{ $t('common.editor.table.row') }}</span>
                          <button
                            class="menubar__button small"
                            @click="commands.addRowBefore"
                          >
                            {{ $t('common.editor.table.add_row_before') }}
                          </button>
                          <button
                            class="menubar__button small"
                            @click="commands.addRowAfter"
                          >
                            {{ $t('common.editor.table.add_row_after') }}
                          </button>
                          <button
                            class="menubar__button small"
                            @click="commands.deleteRow"
                          >
                            {{ $t('common.editor.table.delete_row') }}
                          </button>
                        </div>
                        <div class="pb-1">
                          <button
                            class="menubar__button small"
                            @click="commands.toggleCellMerge"
                          >
                            {{ $t('common.editor.table.combine_cells') }}
                          </button>
                          <button
                            class="menubar__button small"
                            @click="commands.deleteTable"
                          >
                            {{ $t('common.editor.table.delete_table') }}
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </editor-menu-bar>

                <editor-floating-menu :editor="editor" v-slot="{ commands, isActive, menu }">
                  <div
                    class="editor__floating-menu"
                    :class="{ 'is-active': menu.isActive }"
                    :style="`top: ${menu.top}px`"
                  >

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                      @click="commands.heading({ level: 1 })"
                    >
                      <span class="text">H<sup>1</sup></span>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                      @click="commands.heading({ level: 2 })"
                    >
                      <span class="text">H<sup>2</sup></span>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                      @click="commands.heading({ level: 3 })"
                    >
                      <span class="text">H<sup>3</sup></span>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.bullet_list() }"
                      @click="commands.bullet_list"
                    >
                      <i class="icon-list-unordered"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.ordered_list() }"
                      @click="commands.ordered_list"
                    >
                      <i class="icon-list-ordered"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.todo_list() }"
                      @click="commands.todo_list"
                    >
                      <i class="icon-checkbox-checked2"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.blockquote() }"
                      @click="commands.blockquote"
                    >
                      <i class="icon-bubble2"></i>
                    </button>

                    <button
                      class="menubar__button"
                      :class="{ 'is-active': isActive.warning() }"
                      @click="commands.warning"
                    >
                      <i class="icon-warning22"></i>
                    </button>

                    <button
                      class="menubar__button"
                      @click="uploadImage(commands.image)"
                    >
                      <i class="icon-image2"></i>
                    </button>


                    <button
                      class="menubar__button"
                      @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
                    >
                      <i class="icon-table2" />
                    </button>

                  </div>
                </editor-floating-menu>

                <editor-menu-bubble :editor="editor" :keep-in-bounds="true" v-slot="{ commands, isActive, menu, getMarkAttrs }">
                  <div
                    class="menububble shadow"
                    :class="{ 'is-active': menu.isActive }"
                    :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
                  >

                    <form class="menububble__form" v-if="linkMenuIsActive" @submit.prevent="setLinkUrl(commands.link, linkUrl)">
                      <input class="menububble__input" type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" @keydown.esc="hideLinkMenu"/>
                      <button class="menububble__button" @click="setLinkUrl(commands.link, null)" type="button">
                        <i class="icon-cross2" />
                      </button>
                    </form>

                    <template v-else>
                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.bold() }"
                        @click="commands.bold"
                        v-if="!isActive.image()"
                      >
                        <i class="icon-bold2"></i>
                      </button>

                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.italic() }"
                        @click="commands.italic"
                        v-if="!isActive.image()"
                      >
                        <i class="icon-italic2"></i>
                      </button>

                      <button
                        class="menububble__button"
                        @click="showLinkMenu(getMarkAttrs('link'))"
                        :class="{ 'is-active': isActive.link() }"
                      >
                        <i class="icon-link2"></i>
                      </button>

                      <button
                        v-if="!isActive.bullet_list() && !isActive.ordered_list() && !isActive.image()"
                        class="menububble__button"
                        :class="{ 'is-active': isActive.heading({ level: 1 }) }"
                        @click="commands.heading({ level: 1 })"
                      >
                        <span class="text">H<sup>1</sup></span>
                      </button>

                      <button
                        v-if="!isActive.bullet_list() && !isActive.ordered_list() && !isActive.image()"
                        class="menububble__button"
                        :class="{ 'is-active': isActive.heading({ level: 2 }) }"
                        @click="commands.heading({ level: 2 })"
                      >
                        <span class="text">H<sup>2</sup></span>
                      </button>

                      <button
                        v-if="!isActive.bullet_list() && !isActive.ordered_list() && !isActive.image()"
                        class="menububble__button"
                        :class="{ 'is-active': isActive.heading({ level: 3 }) }"
                        @click="commands.heading({ level: 3 })"
                      >
                        <span class="text">H<sup>3</sup></span>
                      </button>

                      <button
                        v-if="!isActive.heading({ level: 1 }) && !isActive.heading({ level: 2 }) && !isActive.heading({ level: 3 }) && !isActive.image()"
                        class="menububble__button"
                        :class="{ 'is-active': isActive.bullet_list() }"
                        @click="commands.bullet_list"
                      >
                        <i class="icon-list-unordered"></i>
                      </button>

                      <button
                        v-if="!isActive.heading({ level: 1 }) && !isActive.heading({ level: 2 }) && !isActive.heading({ level: 3 }) && !isActive.image()"
                        class="menububble__button"
                        :class="{ 'is-active': isActive.ordered_list() }"
                        @click="commands.ordered_list"
                      >
                        <i class="icon-list-ordered"></i>
                      </button>

                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.justifymark() }"
                        @click="commands.justifymark"
                        v-if="!isActive.image()"
                      >
                        <i class="icon-paragraph-justify3"></i>
                      </button>

                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.centermark() }"
                        @click="commands.centermark"
                        v-if="isActive.image()"
                      >
                        <i class="icon-paragraph-center3"></i>
                      </button>

                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.todo_list() }"
                        @click="commands.todo_list"
                        v-if="!isActive.image()"
                      >
                        <i class="icon-checkbox-checked2"></i>
                      </button>

                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.blockquote() }"
                        @click="commands.blockquote"
                        v-if="!isActive.image()"
                      >
                        <i class="icon-bubble2"></i>
                      </button>

                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.warning() }"
                        @click="commands.warning"
                        v-if="!isActive.image()"
                      >
                        <i class="icon-warning22"></i>
                      </button>

                      <button
                        class="menububble__button"
                        :class="{ 'is-active': isActive.tokenmark() }"
                        @click="createTokenFromMark(commands.tokenmark)"
                        v-if="!isActive.image()"
                      >
                        <i class="icon-code"></i>
                      </button>

                    </template>

                  </div>
                </editor-menu-bubble>

                <editor-content class="editor__content" :editor="editor" :autocorrect="isEditorFocused" :spellcheck="isEditorFocused"/>
              </div>

              <div class="suggestion-list suggestion-list__user" v-show="showUserSuggestions" ref="userSuggestions">
                <template v-if="hasUserResults">
                  <div
                    v-for="(user, index) in filteredUsers"
                    v-if="index <= 5"
                    :key="user.id"
                    class="suggestion-list__item"
                    :class="{ 'is-selected': navigatedUserIndex === index }"
                    @click="selectUserMention(user)"
                  >
                    <span v-if="user.full_name">
                      {{ user.full_name }} :
                      <small class="">{{ user.email }}</small>
                    </span>
                    <span v-else>
                      {{ user.email }}
                    </span>
                  </div>
                </template>
                <div v-else class="suggestion-list__item is-empty">
                  {{ $t('modules.wiki.content.mentions.no_users') }}
                </div>
              </div>

              <div class="suggestion-list suggestion-list__hashtag" v-show="showHashtagSuggestions" ref="hashtagSuggestions">
                <template v-if="hasHashtagResults">
                  <div
                    v-for="(hashtag, index) in filteredHashtags"
                    v-if="index <= 5"
                    :key="hashtag.id"
                    class="suggestion-list__item"
                    :class="{ 'is-selected': navigatedHashtagIndex === index }"
                    @click="selectHashtagMention(hashtag)"
                  >
                    {{ hashtag.label }}
                  </div>
                </template>
                <div v-else class="suggestion-list__item is-empty">
                  {{ $t('modules.wiki.content.mentions.no_hashtags') }}
                  <span v-if="!loadingContent" class="badge badge-success text-white ml-1 cursor-pointer" @click="updateContentDebounce"><b>+</b> {{ $t('modules.wiki.content.mentions.create_hashtag') }}</span>
                </div>
              </div>

              <div class="suggestion-list suggestion-list__emoji" v-show="showEmojiSuggestions" ref="emojiSuggestions">
                <template v-if="hasEmojiResults">
                  <div
                    v-for="(emoji, index) in filteredEmojis"
                    v-if="index <= 5"
                    :key="emoji.id"
                    class="suggestion-list__item"
                    :class="{ 'is-selected': navigatedEmojiIndex === index }"
                    @click="selectEmojiMention(emoji)"
                  >
                    {{ emoji.label }}
                  </div>
                </template>
              </div>

              <div class="suggestion-list suggestion-list__token" v-show="showTokenSuggestions" ref="tokenSuggestions">
                <template v-if="hasTokenResults">
                  <div
                    v-for="(token, index) in filteredTokens"
                    v-if="index <= 5"
                    :key="token.id"
                    class="suggestion-list__item"
                    :class="{ 'is-selected': navigatedTokenIndex === index }"
                    @click="selectTokenMention(token)"
                  >
                    {{ token.key }} :
                    <small class="" v-if="token.type === 'string'">{{ token.data.value }}</small>
                  </div>
                </template>
                <div v-else class="suggestion-list__item is-empty">
                  {{ $t('modules.wiki.content.mentions.no_tokens') }} <i class="icon-code bg-dark p-1 rounded"></i>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      slot="sidebar"
      v-if="hasAccessToContent && content && socketConnected"
    >

      <module-entity-template
        :saving="globalLoading || isContentLocked"
        :entity="content"
        v-if="worldCanManageTemplates"
        toggleType="simple"
      />

      <div v-if="isCurrentWorldOwner && content.data && content.data.description" class="bg-white small-radius mb-3 w-100" @click.prevent="openModal('wiki-content-description')">
        <div class="btn btn-outline-secondary border-0 shadow small-radius d-flex justify-content-between text-left font-weight-semibold" @click.prevent="openModal('wiki-content-description')" style="white-space: initial;">
          <img src="@/assets/images/mascot-circle.svg" class="view-guide-image mx-2" style="width: 20px" />
          <div class="px-2">{{ $t('modules.wiki.content.description.button') }}</div>
        </div>
      </div>

      <div :class="{'highlighted': accessIsHighlighted}">
        <module-entity-permissions :saving="globalLoading || isContentLocked" :entity="content" :tribes="content.mentions_list.tribes" :users="content.mentions_list.users" />
      </div>

      <module-entity-informations :saving="globalLoading && !isContentLocked" :entity="content">
        <div class="small text-secondary mt-1 w-100 cursor-pointer" @click.prevent="historyLoaded ? '' : loadHistory(); openModal('wiki-content-history')">{{ $t('modules.wiki.content.history.button') }}</div>
      </module-entity-informations>

      <div class="card">
        <div class="card-header bg-transparent">
          <i class="icon-help mr-2"/>
          <span class="card-title font-weight-semibold">{{ $t('modules.wiki.content.guidelines.title') }}</span>
        </div>
        <div class="card-body">
          <div class="mb-1"><code class="help-syntax font-weight-bold rounded border text-primary">@</code> {{ $t('modules.wiki.content.guidelines.user') }}</div>
          <div class="mb-1"><code class="help-syntax font-weight-bold rounded border text-info">{{</code> {{ $t('modules.wiki.content.guidelines.token') }}</div>
          <div class="mb-1"><code class="help-syntax font-weight-bold rounded border text-slate">#</code> {{ $t('modules.wiki.content.guidelines.hashtag') }}</div>
          <div class="mb-1"><code class="help-syntax font-weight-bold rounded border text-dark">:</code> {{ $t('modules.wiki.content.guidelines.emoji') }}</div>
        </div>
      </div>

    </div>

    <div class="text-center" v-if="hasAccessToContent && !isApp() && content">
      <div class="btn btn-info" @click="initSpreadsheet()" v-if="!spreadsheet">
        <i class="icon-file-excel pr-1"></i>
        <span v-if="content.data.spreadsheet">{{ $t('modules.wiki.content.spreadsheet_view') }}</span>
        <span v-else>{{ $t('modules.wiki.content.spreadsheet_create') }}</span>
      </div>
      <div ref="spreadsheet" class="card text-left"></div>
    </div>

    <el-modal size="lg" :title="$t('modules.wiki.content.history.title')" id="wiki-content-history">
      <div v-if='contentHistory.length > 0'>
        <div v-for="revision in contentHistory" class=" border-bottom pb-2 mb-2">
          <div class="d-flex align-items-center justify-content-between">
            <div class="d-flex align-items-center">
              <img :src="revision.user.avatar.thumb" class="mr-2 avatar rounded-circle">
              <span class="">
                <strong v-if="revision.user.full_name">{{ revision.user.full_name }}</strong>
                <strong v-else>{{ revision.user.email }}</strong>
                {{ $t('modules.wiki.content.history.changed') }}
                {{ $t(`modules.wiki.content.history.fields.${revision.key}`) }}
              </span>
            </div>
            <div class="text-grey">
              {{ revision.date | moment("ll") }}
            </div>
          </div>
          <div class="mt-1 ml-3 pl-4" v-if="revision.diff">
            <div v-html="parseMentions(revision.diff)"></div>
          </div>
        </div>
      </div>
      <div v-if="contentHistory.length == 0 && historyLoaded" class="py-3 text-center text-grey">{{ $t('common.empty_history') }}</div>
      <div class="text-center my-3" v-if="!historyLoaded"><loader /></div>
      <div v-else-if="contentHistoryPage != null" class="text-center mt-3">
        <div class="btn btn-sm btn-outline-secondary" @click="loadHistory()">{{ $t('common.see_more') }}</div>
      </div>
    </el-modal>

    <el-modal size="fullwidth" :title="$t('modules.wiki.content.description.title')" id="wiki-content-description" v-if="isCurrentWorldOwner && content.data && content.data.description">
      <div class="editor" v-if="!content.data.sheet">
        <div class="editor__content">
          <div v-html="nl2br(content.data.description)"></div>
        </div>
      </div>
      <sheet-pilote :sheet="content.data.sheet" v-else />
    </el-modal>

    <el-modal size="sm" :title="$t('modules.wiki.content.actions.delete')" id="wiki-content-remove">
      <form @submit.prevent="removeContent()">
        <div class="form-check mb-3">
          <label class="form-check-label">
            <input type="checkbox" required class="form-check-input" v-model="remove_content_confirm">
            {{ $t('modules.wiki.content.remove_confirmation_text') }}
          </label>
        </div>
        <div class="text-center">
          <button v-if="!loadingContent" type="submit" class="btn btn-danger text-white">{{ $t('common.submit') }}</button>
          <div v-if="loadingContent" class="pt-1 pb-2"><loader></loader></div>
        </div>
      </form>
    </el-modal>

    <wiki-tokens-edit-modal :token="tokenEdit" :callback="callBackEditToken"></wiki-tokens-edit-modal>

    <input ref="imageInput" type="file" accept="image/*" class="d-none" />


    <el-modal size="sm" :title="$t('modules.wiki.content.actions.send')" id="send-wiki-content">
      <form @submit.prevent="sendContent()">
        <div class="form-group">
          <label for="invite_email" class="font-weight-bold label-required">{{ $t('modules.wiki.content.actions.send_user') }}</label>
          <el-select :options="usersToInvite" v-model="contentToSendToUserId" :placeholder="$t('modules.wiki.content.actions.send_user')"/>
        </div>
        <div class="text-center">
          <button v-if="!loadingContent" type="submit" class="btn btn-success text-white" :disabled="!contentToSendToUserId">{{ $t('common.send') }}</button>
          <div v-else class="text-primary pt-1 pb-2"><loader></loader></div>
        </div>
      </form>
    </el-modal>

  </auth-content>

</template>

<script>

if (typeof process.env.CORDOVA_PLATFORM === 'undefined') {
  // const webix = require('@/assets/js/webix/webix/webix') // not necessary since its included in index.html
  const spreadsheet = require('@/assets/js/webix/spreadsheet/spreadsheet_debug')
}

const NEEDACCESS = 412
const emoji = require("emoji-dictionary");

import {Decoration, DecorationSet} from "prosemirror-view"
import io from 'socket.io-client'
import _ from 'lodash'
import Fuse from 'fuse.js'
import tippy from 'tippy.js'
import moment from 'moment-timezone';
import store from '@/store'

import {
  Editor,
  EditorContent,
  EditorFloatingMenu,
  EditorMenuBar,
  EditorMenuBubble,
} from 'tiptap'
import {
  Blockquote,
  BulletList,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Underline,
  History,
  TrailingNode,
  Image,
  Placeholder,
  Table,
  TableHeader,
  TableCell,
  TableRow,
} from 'tiptap-extensions'
import {
  User,
  Token,
  Tokenedit,
  Hashtag,
  Emoji,
  JustifyMark,
  CenterMark,
  TokenMark,
  Warning,
  Collaboration,
} from '../assets/js/tiptap-extensions'

import {
  WIKI_GET_CONTENT,
  WIKI_SET_CONTENT,
  WIKI_GET_CATEGORIES,
  WIKI_CREATE_TOKEN,
  EVENT_WIKI_EDIT_TOKEN,
  WIKI_ADD_MEDIA_CONTENT,
  WIKI_GET_HISTORY_CONTENT,
  WIKI_DELETE_CONTENT,
  WIKI_SEND_CONTENT,
  WIKI_CLONE_CONTENT,
} from '../mutations-types'

import {
  GLOBAL_BANNERS,
  USER_SET_WORLD,
} from '@/store/mutations-types'

import WikiTokensEditModal from '../components/TokensEditModal'
import SheetPilote from '@/../Modules/Quest/components/SheetPilote'

export default {
  name: 'WikiContentPage',
  components: {
    WikiTokensEditModal,
    EditorContent,
    EditorFloatingMenu,
    EditorMenuBubble,
    EditorMenuBar,
    SheetPilote,
  },
  data () {
    return {
      accessIsHighlighted: false,
      contentIsHighlighted: false,
      hasAccessToContent: null,
      hasUserStartedEditingContent: false,
      oldContent: null,
      updateContentTimeout: null,
      editor: null,
      content: false,
      contentLoaded: false,
      contentHistory: [],
      contentHistoryPage: 1,
      historyLoaded: false,
      isEditorFocused: false,
      categories: false,
      loadingFinished: false,
      loadingToken: false,
      loadingTitle: false,
      loadingAccess: false,
      loadingCategory: false,
      loadingOwner: false,
      loadingContent: false,
      loadingSpreadsheet: false,
      tokenEdit: null,
      query: null,
      queryType: null,
      suggestionRange: null,
      filteredUsers: [],
      filteredTokens: [],
      filteredHashtags: [],
      filteredEmojis: [],
      navigatedUserIndex: 0,
      navigatedTokenIndex: 0,
      navigatedHashtagIndex: 0,
      navigatedEmojiIndex: 0,
      insertUser: () => {},
      insertToken: () => {},
      insertHashtag: () => {},
      insertEmoji: () => {},
      observer: null,
      spreadsheet: null,
      erasedContent: null,
      willSave: false,
      remove_content_confirm: false,
      linkUrl: null,
      linkMenuIsActive: false,
      socket: null,
      socketConnected: false,
      usersConnected: {},
      randomClientID: Math.floor(Math.random() * 0xFFFFFFFF),
      contentToSendToUserId: false,
    }
  },
  mounted () {
    this.loadCategories()

    setInterval(() => {
      if (this.isContentLocked) {
        this.content = false
        this.refreshContent()
      }
    }, 20000)

    this.$nextTick(() => {
      if (!this.spreadsheet && this.content.data && this.content.data.spreadsheet) {
        this.initSpreadsheet()
      }
    })
  },
  methods: {
    // initEditor ({ doc, version }) {
    //   if (this.editor) {
    //     this.editor.destroy()
    //   }
    //   this.editor = new Editor({
    // },
    loadSocket (accesskey) {
      let namespace = accesskey.replace(/-/g, '')
      this.socket ? this.socket.destroy() : '' // disconnect if already connected
      let socketServerUrl = process.env.VUE_APP_NODEJS_URL
      if (process.env.VUE_APP_NODEJS_PORT && process.env.VUE_APP_NODEJS_PORT != '') {
        socketServerUrl+= `:${process.env.VUE_APP_NODEJS_PORT}`
      }
      // let socketServerPath = null
      // if (process.env.VUE_APP_NODEJS_PATH && process.env.VUE_APP_NODEJS_PATH != '') {
      //   socketServerPath = process.env.VUE_APP_NODEJS_PATH
      // }
      this.socket = io(`${socketServerUrl}/${namespace}`, {
        // transports: ['websocket'],
        // path: socketServerPath,
        query: {
          contentId: this.$route.params.content,
          accesskey: this.content.accesskey,
          userId: this.currentUser.id,
        },
      })
      .on('connect', () => {
        this.socketConnected = true
        // this.notifSuccess('SOCKET connect');
        // this.notifSuccess(this.socket.id);
      })
      .on('connect_error', (error) => {
        // this.notifError('SOCKET connect_error');
        // this.notifError(error);
      })
      .on('connect_timeout', (timeout) => {
        // this.notifError('SOCKET connect_timeout');
        // this.notifError(timeout);
      })
      .on('error', (error) => {
        // this.notifError('SOCKET error');
        // this.notifError(error);
      })
      // get the current document and its version
      // .on('init', data => this.initEditor(data))
      // send all updates to the collaboration extension
      .on('updateContentFromSocket', data => {
        this.editor.extensions.options.collaboration.update(data)
      })
      // get count of connected users
      .on('usersConnected', usersConnected => this.setUsersConnected(usersConnected))

    },
    highlightContent() {
      this.contentIsHighlighted = true
      setTimeout(() => {
        this.contentIsHighlighted = false
      }, 500)
    },
    highlightAccess() {
      this.accessIsHighlighted = true
      setTimeout(() => {
        this.accessIsHighlighted = false
      }, 500)
    },
    setUsersConnected(usersConnected) {
      this.usersConnected = usersConnected
    },
    cloneContent () {
      this.loadingContent = true
      this.$store.dispatch('wiki/' + WIKI_CLONE_CONTENT, {
        worldId: this.$route.params.world,
        contentId: this.content.id,
      }).then(content => {
        this.$router.push({name: 'wiki.content', params: { world: this.$route.params.world, content: content.id}})
        location.reload()
      })
    },
    sendContent () {
      this.loadingContent = true
      this.$store.dispatch('wiki/' + WIKI_SEND_CONTENT, {
        world_id: this.$route.params.world,
        content_id: this.content.id,
        user_id: this.contentToSendToUserId,
      }).then(msg => {
        this.loadingContent = false
        this.notifSuccess(this.$t('modules.wiki.content.actions.send_ok'))
        this.closeModal('send-wiki-content')
      })
      .catch(error => {
        this.loadingContent = false
        this.notifError(error)
      })
    },
    removeContent () {
      this.loadingContent = true
      if (this.remove_content_confirm) {
        this.$store.dispatch('wiki/' + WIKI_DELETE_CONTENT, {
          world_id: this.$route.params.world,
          id: this.$route.params.content
        }).then(data => {
          this.loadingContent = false
          this.notifSuccess(this.$t('modules.wiki.content.messages.delete_success'))
          this.closeModal('wiki-content-remove')
          this.$router.push({name: 'wiki.index', params: {world: this.$route.params.world } })
          this.remove_content_confirm = false
        })
        .catch(error => {
          this.loadingContent = false
          this.notifError(this.$t('modules.wiki.content.messages.delete_error'))
        })
      }
    },
    getBase64 (file) {
       var reader = new FileReader();
       reader.readAsDataURL(file);
       return reader.onload = function () {
         return reader.result
       }
    },

    initSpreadsheet () {
      if (this.isApp()) {
        return
      }

      this.$nextTick(() => {

        this.spreadsheet = webix.ui({
          container: this.$refs.spreadsheet,
          $scope: this,
          bottombar: true,
          // liveEditor: true,
          buttons: {
            "sheet": [
              "excel-import",
              "excel-export"
            ],
            "font": [
              "font-size",
              "font-weight",
              "font-style",
              "text-decoration",
              "color",
              "background",
              "borders"
            ],
            "align": [
              "text-align",
              "vertical-align",
              "wrap",
              "span"
            ],
            "format": [
              "format"
            ]
          },
          view: "spreadsheet",
          height: 500,
        })

        if (this.content.data.spreadsheet) {
          webix.$$(this.spreadsheet).parse(this.content.data.spreadsheet)
        }

        webix.$$(this.spreadsheet).attachEvent("onCellChange", (row, column, value) => {
          this.$set(this.content.data, 'spreadsheet', webix.$$(this.spreadsheet).serialize())
          this.updateContentDebounce()
        })

        webix.$$(this.spreadsheet).attachEvent("onSheetAdd", (name) => {
          this.$set(this.content.data, 'spreadsheet', webix.$$(this.spreadsheet).serialize())
          this.updateContentDebounce()
        })

        webix.$$(this.spreadsheet).attachEvent("onSheetRemove", (name) => {
          this.$set(this.content.data, 'spreadsheet', webix.$$(this.spreadsheet).serialize())
          this.updateContentDebounce()
        })

        webix.$$(this.spreadsheet).attachEvent("onSheetRename", (name, newName) => {
          this.$set(this.content.data, 'spreadsheet', webix.$$(this.spreadsheet).serialize())
          this.updateContentDebounce()
        })

        webix.$$(this.spreadsheet).attachEvent("onStyleSet", (name, val) => {
          this.$set(this.content.data, 'spreadsheet', webix.$$(this.spreadsheet).serialize())
          this.updateContentDebounce()
        })

      })
    },
    trimItself (event) {
      event.target.innerText = event.target.innerText.trim()
    },
    loadHistory () {
      this.historyLoaded = false
      this.$store.dispatch('wiki/' + WIKI_GET_HISTORY_CONTENT, {
        worldId: this.$route.params.world,
        contentId: this.$route.params.content,
        page: this.contentHistoryPage,
      }).then(history => {
        this.contentHistoryPage = history.next_page_url ? this.contentHistoryPage + 1 : null
        this.contentHistory = this.contentHistory.concat(history.data)
        this.historyLoaded = true
      })
      .catch(error => {
        this.notifError(error)
        this.historyLoaded = true
      })
    },
    updateTitle (event) {
      if (this.isContentLocked) {
        return
      }
      const oldTitle = this.content.name
      const newTitle = event.target.innerText.trim()

      if (oldTitle === newTitle) {
        return
      }

      this.content.name = newTitle
      this.loadingTitle = true

      this.$store.dispatch('wiki/' + WIKI_SET_CONTENT, {
        world_id: this.$route.params.world,
        id: this.$route.params.content,
        name: newTitle
      }).then(content => {
        this.content = content
        this.loadingTitle = false
      })
      .catch(error => {
        this.notifError(this.$t('modules.wiki.content.messages.edit_error'))
        this.loadingTitle = false
      })
    },
    refreshContent () {
      this.loadingContent = true
      this.$store.dispatch('wiki/' + WIKI_GET_CONTENT, {
        worldId: this.$route.params.world,
        contentId: this.$route.params.content
      }).then(content => {
        this.content = content
        this.editor.setContent(this.parseMentions(content.content))
        // this.$nextTick(() => {
        //   Object.keys(this.content.mentions.empty_tokens).forEach( tokenKey => {
        //     let tokenRef = 'empty-token-' + this.content.mentions.empty_tokens[tokenKey]
        //     // Re-init values
        //     this.$refs[tokenRef][0].value = ""
        //   })
        // })
        this.loadingToken = false
        this.loadingContent = false
      })
    },
    callBackEditToken () {
      this.refreshContent()
      this.tokenEdit = null
    },
    updateContentDebounce: _.debounce(function() {
      this.updateContent();
    }, 2000),
    updateContent (checkIfContentEdited = false) {
      if (this.isContentLocked || ! this.$route.params.content) {
        return
      }
      const newContent = this.editor.getHTML().trim()

      if (checkIfContentEdited && this.oldContent.trim().replace(/<(span)[^>]+>/ig,'<$1>') === newContent.replace(/<(span)[^>]+>/ig,'<$1>')) {
        return
      }

      this.content.content = newContent

      this.loadingContent = true

      this.$store.dispatch('wiki/' + WIKI_SET_CONTENT, {
        world_id: this.$route.params.world,
        id: this.content.id,
        content: newContent,
        access: this.content.access,
        access_all: this.content.access_all,
        spreadsheet: this.content.data.spreadsheet || null,
        user_id: this.content.user_id,
        template: this.content.template || false,
        category_id: this.content.category_id || null
      }).then(content => {
        this.content = content
        this.loadingContent = false

        this.oldContent = this.parseMentions(content.content)
        // if (!this.isEditorFocused && !this.linkMenuIsActive) {
        //   this.editor.setContent(this.oldContent)
        // }

        this.erasedContent = null
        this.willSave = false
      })
      .catch(error => {
        this.willSave = false
        this.loadingContent = false
        if (error.response.data.errors.error && error.response.data.errors.error[0] && error.response.data.errors.error[0] == 'locked') {
          this.notif('error', {
            content: this.$t('modules.wiki.tokens.locked.message'),
            options: {
              timeout: 6000
            }
          })
          this.erasedContent = newContent
          this.refreshContent()
        } else {
          this.notifError(error)
        }
      })
    },
    createTokens () {
      let tokensToCreate = []
      _.forEach(this.content.mentions.empty_tokens, empty_token => {
        if (!this.tokenExist(empty_token) && this.$refs['empty-token-' + empty_token][0].value) {
          tokensToCreate.push({
            key: empty_token,
            value: this.$refs['empty-token-' + empty_token][0].value,
          })
        }
      })

      this.loadingToken = true
      this.$store.dispatch('wiki/' + WIKI_CREATE_TOKEN, {
        world_id: this.$route.params.world,
        tokens: tokensToCreate,
      }).then(token => {
        this.refreshContent()
      })
      .catch(error => {
        this.notifError(this.$t('modules.wiki.token.messages.create_error'))
        this.loadingToken = false
      })
    },
    createTokenFromMark (command) {
      command({text: window.getSelection().toString()})
      this.updateContentDebounce()
    },
    tokenExist(tokenKey) {
      return this.content.mentions_list.tokens.filter(token => {
        return token.key == tokenKey
      }).length > 0
    },
    loadCategories () {
      this.$store.dispatch('wiki/' + WIKI_GET_CATEGORIES, this.$route.params.world).then(categories => {
        this.categories = categories.filter(category => {
          return category.name != "FUNDAMENTAL"
        })
      })
      .catch(error => {
        this.notifError(this.$t('modules.wiki.categories.messages.get_error'))
      })
    },
    // navigate to the previous item
    // if it's the first item, navigate to the last one
    upHandler() {
      this[this.currentSuggestionIndexKeys.index] = ((this[this.currentSuggestionIndexKeys.index] + this[this.currentSuggestionIndexKeys.filteredList].length) - 1) % this[this.currentSuggestionIndexKeys.filteredList].length
    },
    // navigate to the next item
    // if it's the last item, navigate to the first one
    downHandler() {
      this[this.currentSuggestionIndexKeys.index] = (this[this.currentSuggestionIndexKeys.index] + 1) % this[this.currentSuggestionIndexKeys.filteredList].length
    },
    enterHandler() {
      const element = this[this.currentSuggestionIndexKeys.filteredList][this[this.currentSuggestionIndexKeys.index]]
      if (element) {
        this[this.currentSuggestionIndexKeys.selectFunction](element)
      }
    },
    // we have to replace our suggestion text with a user
    // so it's important to pass also the position of your suggestion text
    selectUserMention(user) {
      this.insertUser({
        range: this.suggestionRange,
        attrs: {
          id: user.id,
          label: user.full_name ? user.full_name : user.email,
        },
      })
      this.editor.focus()
    },
    // we have to replace our suggestion text with a hashtag
    // so it's important to pass also the position of your suggestion text
    selectHashtagMention(hashtag) {
      this.insertHashtag({
        range: this.suggestionRange,
        attrs: {
          id: hashtag.id,
          label: hashtag.label,
        },
      })
      this.editor.focus()
    },
    // we have to replace our suggestion text with a emoji
    // so it's important to pass also the position of your suggestion text
    selectEmojiMention(emoji) {
      this.insertEmoji({
        range: this.suggestionRange,
        attrs: {
          id: emoji.id,
          code: emoji.code,
          label: emoji.label,
        },
      })
      this.editor.focus()
    },
    // we have to replace our suggestion text with a user
    // so it's important to pass also the position of your suggestion text
    selectTokenMention(token) {
      let value = false
      if (token.type === 'string') {
        value = token.data.value
      }

      let label = token.name

      if (value !== false) {
        label = value
      }

      const tokenData = {
        range: this.suggestionRange,
        attrs: {
          id: token.key,
          key: token.key,
          label: label,
        },
      }

      this.insertToken(tokenData)
      this.editor.focus()
      return tokenData
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderUserPopup(node) {
      if (this.popup) {
        return
      }
      this.popup = tippy(node, {
        content: this.$refs.userSuggestions,
        trigger: 'mouseenter',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        duration: 100,
        showOnInit: true,
        arrow: false,
        animateFill: false,
        arrowType: 'round',
      })
      // we have to update tippy whenever the DOM is updated
      if (MutationObserver) {
        this.observer = new MutationObserver(() => {
          this.popup.popperInstance.scheduleUpdate()
        })
        this.observer.observe(this.$refs.userSuggestions, {
          childList: true,
          subtree: true,
          characterData: true,
        })
      }
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderHashtagPopup(node) {
      if (this.popup) {
        return
      }
      this.popup = tippy(node, {
        content: this.$refs.hashtagSuggestions,
        trigger: 'mouseenter',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        duration: 100,
        showOnInit: true,
        arrow: false,
        animateFill: false,
        arrowType: 'round',
      })
      // we have to update tippy whenever the DOM is updated
      if (MutationObserver) {
        this.observer = new MutationObserver(() => {
          this.popup.popperInstance.scheduleUpdate()
        })
        this.observer.observe(this.$refs.hashtagSuggestions, {
          childList: true,
          subtree: true,
          characterData: true,
        })
      }
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderEmojiPopup(node) {
      if (this.popup) {
        return
      }
      this.popup = tippy(node, {
        content: this.$refs.emojiSuggestions,
        trigger: 'mouseenter',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        duration: 100,
        showOnInit: true,
        arrow: false,
        animateFill: false,
        arrowType: 'round',
      })
      // we have to update tippy whenever the DOM is updated
      if (MutationObserver) {
        this.observer = new MutationObserver(() => {
          this.popup.popperInstance.scheduleUpdate()
        })
        this.observer.observe(this.$refs.emojiSuggestions, {
          childList: true,
          subtree: true,
          characterData: true,
        })
      }
    },
    // renders a popup with suggestions
    // tiptap provides a virtualNode object for using popper.js (or tippy.js) for popups
    renderTokenPopup(node) {
      if (this.popup) {
        return
      }
      this.popup = tippy(node, {
        content: this.$refs.tokenSuggestions,
        trigger: 'mouseenter',
        interactive: true,
        theme: 'dark',
        placement: 'top-start',
        duration: 100,
        showOnInit: true,
        arrow: false,
        animateFill: false,
        arrowType: 'round',
      })
      // we have to update tippy whenever the DOM is updated
      if (MutationObserver) {
        this.observer = new MutationObserver(() => {
          this.popup.popperInstance.scheduleUpdate()
        })
        this.observer.observe(this.$refs.tokenSuggestions, {
          childList: true,
          subtree: true,
          characterData: true,
        })
      }
    },
    destroyPopup() {
      if (this.popup) {
        this.popup.destroy()
        this.popup = null
      }
      if (this.observer) {
        this.observer.disconnect()
      }
    },
    uploadImage (tiptapCommand) {
      this.$refs.imageInput.click()
      this.$refs.imageInput.onchange = (fileInput) => {
        if (fileInput.target.files.length > 0) {
          let reader = new FileReader();
          reader.onloadend = () => {
            var b64 = reader.result
            var file = fileInput.target.files[0]

            let formData = new FormData()

            formData.append('worldId', this.$route.params.world)
            formData.append('contentId', this.$route.params.content)
            formData.append('media', file)

            store.dispatch('wiki/' + WIKI_ADD_MEDIA_CONTENT, formData).then(urls => {
              tiptapCommand({src: urls.full})
            })
          }
          reader.readAsDataURL(fileInput.target.files[0]);
        }
      }
    },
    parseMentions(content) {
      let re, match
      // TOKENS
      re = /{{([^}]*)}}/gm
      let tokens = content.match(re)

      while (match = re.exec(content)) {
        let tokenKey = match[1]

        let token = this.content.mentions_list.tokens.find((el) => {
          return el.key == tokenKey
        })
        if (token) {
          content = content.replace(`{{${tokenKey}}}`, `<span class="token" data-token-id="${tokenKey}" data-token-key="${tokenKey}" data-char="{{" data-char-after="}}" data-token-name="${token.name} }}">${token.value}</span>`)
        }
      }

      // USERS
      re = /@(\d*)[\s<@]/gm
      let users = content.match(re)

      while (match = re.exec(content)) {
        let userId = match[1]

        let user = this.content.mentions_list.users.find((el) => {
          return el.id == userId
        })
        if (user) {
          let userLabel = user.full_name ? user.full_name : user.email
          content = content.replace(`@${userId}`, `<span class="user" data-user-id="${userId}" data-char="@" contenteditable="false">${userLabel}</span>`)
        }
      }
      // HASHTAGS
      re = /#(\w*)[\s<#]/gm
      let hashtags = content.match(re)
      while (match = re.exec(content)) {
        let hashtagId = match[1]

        if (hashtagId && hashtagId !== '') {
          let hashtag = this.content.mentions_list.hashtags.find((el) => {
            return el.label == hashtagId
          })
          if (hashtag) {
            content = content.replace(`#${hashtagId}`, `<span class="hashtag" data-hashtag-id="${hashtagId}" data-char="#" contenteditable="false">${hashtagId}</span>`)
          }
        }
      }
      return content
    },
    emojisList () {
      let codes = emoji.unicode
      let names = emoji.names
      let list = []
      names.forEach((name, index) => {
        list.push({
          id: name,
          code: codes[index],
          label: codes[index] + ' :' + name + ':',
        })
      })
      return list
    },
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$nextTick(() => {
        this.$refs.linkInput.focus()
      })
    },
    hideLinkMenu() {
      this.linkUrl = null
      this.linkMenuIsActive = false
    },
    setLinkUrl(command, url) {
      command({ href: url })
      this.hideLinkMenu()
    },
  },
  beforeRouteLeave (to, from, next) {
    if (this.willSave) {
      this.updateContent()
    }
    next()
  },
  beforeRouteEnter (to, from, next) {

    // If world URL changed : reload page
    if (to.params.world != store.getters['auth/getWorld'].id) {
      //changeWorld
      let worldsByKey = _.keyBy(store.getters['auth/getProfile'].worlds, world => world.id)
      store.dispatch('global/' + GLOBAL_BANNERS, worldsByKey[to.params.world].id)
      store.dispatch('auth/' + USER_SET_WORLD, worldsByKey[to.params.world])
    }

    store.dispatch('wiki/' + EVENT_WIKI_EDIT_TOKEN, null)
    store.dispatch('wiki/' + WIKI_GET_CONTENT, {
      worldId: to.params.world,
      contentId: to.params.content
    }).then(content => {
      next(vm => {

        store.commit('global/changeSidebarRight', true)
        vm.hasAccessToContent = true
        vm.content = {...content}
        vm.contentLoaded = true

        vm.owner_user_id = content.user_id

        const contentBody = vm.parseMentions(content.content)

        vm.oldContent = contentBody

        vm.editor = new Editor({
          editable: !vm.isContentLocked,
          extensions: [
            new Warning(),
            new Blockquote(),
            new BulletList(),
            new HardBreak(),
            new Heading({ levels: [1, 2, 3] }),
            new ListItem(),
            new OrderedList(),
            new TodoItem({
              nested: true,
            }),
            new TodoList(),
            new Image(),
            new Link(),
            new Bold(),
            new JustifyMark(),
            new CenterMark(),
            new TokenMark(),
            new Italic(),
            new History(),
            new Tokenedit(),
            new TrailingNode({
              node: 'paragraph',
              notAfter: ['paragraph'],
            }),
            new Underline(),
            new Placeholder({
              emptyNodeClass: 'is-empty',
              emptyNodeText: vm.$t('modules.wiki.content.placeholder'),
              showOnlyWhenEditable: true,
            }),
            new Table({
              resizable: true,
            }),
            new TableHeader(),
            new TableCell(),
            new TableRow(),
            new User({
              // a list of all suggested items
              items: () => content.mentions_list.users,
              // is called when a suggestion starts
              onEnter: ({
                items, query, range, command, virtualNode,
              }) => {
                vm.query = query
                vm.queryType = 'user'
                vm.filteredUsers = items
                vm.suggestionRange = range
                vm.renderUserPopup(virtualNode)
                // we save the command for inserting a selected user
                // this allows us to call it inside of our custom popup
                // via keyboard navigation and on click
                vm.insertUser = command
              },
              // is called when a suggestion has changed
              onChange: ({
                items, query, range, virtualNode,
              }) => {
                vm.query = query
                vm.filteredUsers = items
                vm.suggestionRange = range
                vm.navigatedUserIndex = 0
                vm.renderUserPopup(virtualNode)
              },
              // is called when a suggestion is cancelled
              onExit: () => {
                // reset all saved values
                vm.query = null
                vm.queryType = null
                vm.filteredUsers = []
                vm.suggestionRange = null
                vm.navigatedUserIndex = 0
                vm.destroyPopup()
              },
              // is called on every keyDown event while a suggestion is active
              onKeyDown: ({ event }) => {
                // pressing up arrow
                if (event.keyCode === 38) {
                  vm.upHandler()
                  return true
                }
                // pressing down arrow
                if (event.keyCode === 40) {
                  vm.downHandler()
                  return true
                }
                // pressing enter
                if (event.keyCode === 13) {
                  vm.enterHandler()
                  return true
                }
                return false
              },
              // is called when a suggestion has changed
              // this function is optional because there is basic filtering built-in
              // you can overwrite it if you prefer your own filtering
              // in this example we use fuse.js with support for fuzzy search
              onFilter: (items, query) => {
                if (!query) {
                  return items
                }
                const fuse = new Fuse(items, {
                  threshold: 0.2,
                  keys: ['name', 'email'],
                })
                return fuse.search(query)
              },
            }), // new User()

            new Emoji({
              // a list of all suggested items
              items: () => vm.emojisList(),
              // is called when a suggestion starts
              onEnter: ({
                items, query, range, command, virtualNode,
              }) => {
                vm.query = query
                vm.queryType = 'emoji'
                vm.filteredEmojis = []
                vm.suggestionRange = range
                vm.renderEmojiPopup(virtualNode)
                // we save the command for inserting a selected emoji
                // this allows us to call it inside of our custom popup
                // via keyboard navigation and on click
                vm.insertEmoji = command
              },
              // is called when a suggestion has changed
              onChange: ({
                items, query, range, virtualNode,
              }) => {
                if (query.length > 1) {
                  vm.filteredEmojis = items
                } else {
                  vm.filteredEmojis = []
                }
                vm.query = query
                vm.suggestionRange = range
                vm.navigatedEmojiIndex = 0
                vm.renderEmojiPopup(virtualNode)
              },
              // is called when a suggestion is cancelled
              onExit: () => {
                // reset all saved values
                vm.query = null
                vm.queryType = null
                vm.filteredEmojis = []
                vm.suggestionRange = null
                vm.navigatedEmojiIndex = 0
                vm.destroyPopup()
              },
              // is called on every keyDown event while a suggestion is active
              onKeyDown: ({ event }) => {
                // pressing up arrow
                if (event.keyCode === 38) {
                  vm.upHandler()
                  return true
                }
                // pressing down arrow
                if (event.keyCode === 40) {
                  vm.downHandler()
                  return true
                }
                // pressing enter
                if (event.keyCode === 13) {
                  vm.enterHandler()
                  return true
                }
                return false
              },
              // is called when a suggestion has changed
              // this function is optional because there is basic filtering built-in
              // you can overwrite it if you prefer your own filtering
              // in this example we use fuse.js with support for fuzzy search
              onFilter: (items, query) => {
                if (!query) {
                  return items
                }
                const fuse = new Fuse(items, {
                  threshold: 0.2,
                  keys: ['label'],
                })
                return fuse.search(query)
              },
            }), // new Emoji()


            new Hashtag({
              // a list of all suggested items
              items: () => content.mentions_list.hashtags,
              // is called when a suggestion starts
              onEnter: ({
                items, query, range, command, virtualNode,
              }) => {
                vm.query = query
                vm.queryType = 'hashtag'
                vm.filteredHashtags = items
                vm.suggestionRange = range
                vm.renderHashtagPopup(virtualNode)
                // we save the command for inserting a selected hashtag
                // this allows us to call it inside of our custom popup
                // via keyboard navigation and on click
                vm.insertHashtag = command
              },
              // is called when a suggestion has changed
              onChange: ({
                items, query, range, virtualNode,
              }) => {
                vm.query = query
                vm.filteredHashtags = items
                vm.suggestionRange = range
                vm.navigatedHashtagIndex = 0
                vm.renderHashtagPopup(virtualNode)
              },
              // is called when a suggestion is cancelled
              onExit: () => {
                // reset all saved values
                vm.query = null
                vm.queryType = null
                vm.filteredHashtags = []
                vm.suggestionRange = null
                vm.navigatedHashtagIndex = 0
                vm.destroyPopup()
              },
              // is called on every keyDown event while a suggestion is active
              onKeyDown: ({ event }) => {
                // pressing up arrow
                if (event.keyCode === 38) {
                  vm.upHandler()
                  return true
                }
                // pressing down arrow
                if (event.keyCode === 40) {
                  vm.downHandler()
                  return true
                }
                // pressing enter
                if (event.keyCode === 13) {
                  vm.enterHandler()
                  return true
                }
                return false
              },
              // is called when a suggestion has changed
              // this function is optional because there is basic filtering built-in
              // you can overwrite it if you prefer your own filtering
              // in this example we use fuse.js with support for fuzzy search
              onFilter: (items, query) => {
                if (!query) {
                  return items
                }
                const fuse = new Fuse(items, {
                  threshold: 0.2,
                  keys: ['label'],
                })
                return fuse.search(query)
              },
            }), // new Hashtag()

            new Token({
              // a list of all suggested items
              items: () => content.mentions_list.tokens,
              // is called when a suggestion starts
              onEnter: ({
                items, query, range, command, virtualNode,
              }) => {
                vm.query = query
                vm.queryType = 'token'
                vm.filteredTokens = items
                vm.suggestionRange = range
                vm.renderTokenPopup(virtualNode)
                // we save the command for inserting a selected token
                // this allows us to call it inside of our custom popup
                // via keyboard navigation and on click
                vm.insertToken = command
              },
              // is called when a suggestion has changed
              onChange: ({
                items, query, range, virtualNode,
              }) => {
                vm.query = query
                vm.filteredTokens = items
                vm.suggestionRange = range
                vm.navigatedTokenIndex = 0
                vm.renderTokenPopup(virtualNode)
              },
              // is called when a suggestion is cancelled
              onExit: () => {
                // reset all saved values
                vm.query = null
                vm.queryType = null
                vm.filteredTokens = []
                vm.suggestionRange = null
                vm.navigatedTokenIndex = 0
                vm.destroyPopup()
              },
              // is called on every keyDown event while a suggestion is active
              onKeyDown: ({ event }) => {
                // pressing up arrow
                if (event.keyCode === 38) {
                  vm.upHandler()
                  return true
                }
                // pressing down arrow
                if (event.keyCode === 40) {
                  vm.downHandler()
                  return true
                }
                // pressing enter
                if (event.keyCode === 13) {
                  vm.enterHandler()
                  return true
                }
                return false
              },
              // is called when a suggestion has changed
              // this function is optional because there is basic filtering built-in
              // you can overwrite it if you prefer your own filtering
              // in this example we use fuse.js with support for fuzzy search
              onFilter: (items, query) => {
                if (!query) {
                  return items
                }
                const fuse = new Fuse(items, {
                  threshold: 0.2,
                  keys: ['key', 'data.value'],
                })
                return fuse.search(query)
              },
            }), // new Token()

            new Collaboration({
              clientID: vm.randomClientID,
              onSendable: ({ sendable }) => {
                vm.socket.emit('updateContent', sendable)
              },
            }),

          ],
          content: contentBody,
          onBlur: () => {
            if (!vm.isContentLocked) {
              vm.showTokenSuggestions || vm.showUserSuggestions || vm.showHashtagSuggestions  || vm.showEmojiSuggestions ? '' : vm.updateContent(true)
            }
            vm.isEditorFocused = false
          },
          onFocus: () => {
            vm.isEditorFocused = true
            vm.hasUserStartedEditingContent = true
          },
        })

      })
    }).catch(error => {
      const {status} = error.response
      if (status === NEEDACCESS) {

        store.commit('global/changeSidebarRight', false)
        next(vm => {
          vm.hasAccessToContent = false
        })

      }
    })
  },
  beforeDestroy() {
    this.editor ? this.editor.destroy() : ''
    this.socket ? this.socket.destroy() : ''
  },
  computed: {
    hasEmptyTokens(tokenKey) {
      let hasEmptyTokens = false
      if (!this.content.mentions || !this.content.mentions.empty_tokens) {
        return false
      }

      _.forEach(this.content.mentions.empty_tokens, token => {
        if(!this.tokenExist(token)) {
          hasEmptyTokens = true
        }
      })
      return hasEmptyTokens
    },
    cardsThrowedForThisWorld () {
      if (!this.currentWorld.data || !this.currentWorld.data.wiki_content_from_quest) {
        return 0
      }
      return this.currentWorld.data.wiki_content_from_quest
    },
    showStepsBlock () {
      return this.isCurrentWorldOwner &&
             this.cardsThrowedForThisWorld >= 1  &&
             this.cardsThrowedForThisWorld <= 15 &&
             this.content.data &&
             this.content.template === false &&
             this.content.data.description &&
             this.content.data.sheet_id
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    currentWorld () {
      return this.$store.getters['auth/getWorld']
    },
    worldCanManageTemplates () {
      return this.$store.getters['auth/worldCanManageTemplates'];
    },
    eventWikiEditToken () {
      return this.$store.getters['wiki/getEditToken']
    },
    globalLoading() {
      return this.loadingContent || this.loadingCategory || this.loadingAccess || this.loadingTitle || this.loadingOwner || this.loadingSpreadsheet
    },
    hasUserResults() {
      return this.filteredUsers.length
    },
    hasHashtagResults() {
      return this.filteredHashtags.length
    },
    hasEmojiResults() {
      return this.filteredEmojis.length
    },
    hasTokenResults() {
      return this.filteredTokens.length
    },
    usersToInvite () {
      let usersToInvite = ['']
      _.map(this.content.has_access, (elem) => {
        usersToInvite.push({
          avatar: elem.avatar.thumb,
          value: elem.id,
          title: elem.full_name,
          detail: elem.email,
        })
      })
      return usersToInvite.filter(user => {
        return user.value != this.currentUser.id
      })
    },
    currentSuggestionIndexKeys () {
      if (this.queryType === 'user') {
        return {
          index: 'navigatedUserIndex',
          filteredList: 'filteredUsers',
          selectFunction: 'selectUserMention',
        }
      }
      if (this.queryType === 'token') {
        return {
          index: 'navigatedTokenIndex',
          filteredList: 'filteredTokens',
          selectFunction: 'selectTokenMention',
        }
      }
      if (this.queryType === 'emoji') {
        return {
          index: 'navigatedEmojiIndex',
          filteredList: 'filteredEmojis',
          selectFunction: 'selectEmojiMention',
        }
      }
      if (this.queryType === 'hashtag') {
        return {
          index: 'navigatedHashtagIndex',
          filteredList: 'filteredHashtags',
          selectFunction: 'selectHashtagMention',
        }
      }
      return {}
    },
    showUserSuggestions() {
      return (!this.globalLoading && this.query && this.queryType === 'user') || this.hasUserResults
    },
    showHashtagSuggestions() {
      return (!this.globalLoading && this.query && this.queryType === 'hashtag') || this.hasHashtagResults
    },
    showEmojiSuggestions() {
      return (!this.globalLoading && this.query && this.queryType === 'emoji') || this.hasEmojiResults
    },
    showTokenSuggestions() {
      return (!this.globalLoading && this.query && this.queryType === 'token') || this.hasTokenResults
    },
    isCurrentWorldOwner () {
      return this.$store.getters['auth/isWorldOwner'];
    },
    isSidebarRightOpened () {
      return this.$store.getters['global/isSidebarRightOpened']
    },
    isContentLocked () {
      return false
      // if (this.content.locked_to == null || this.content.locked_by_id == null) {
      //   return false
      // }
      // let now = moment()
      // let lockedTo = moment(this.content.locked_to)
      // return this.currentUser.id !== this.content.locked_by_id && lockedTo.isAfter(now)
    },
    accesses () {
      let accesses = null
      if (!this.content || !this.content.access) {
        accesses = []
      } else {
        accesses = this.content.access
      }
      return Object.assign({}, accesses)
    },
    usersMentionsById () {
      let users = {}
      if (this.contentLoaded) {
        _.forEach(this.content.mentions_list.users, user => {
          users[user.id] = user
        })
      }
      return users
    },
  },
  watch: {
    usersConnected: {
      deep: true,
      handler: function (newVal, oldVal) {
        if (this.editor) {
          const { state, view, schema } = this.editor
          let currentUserId = this.currentUser.id
          let usersConnected = this.usersConnected
          let usersData = this.usersMentionsById
          let props = {
            decorations (state) {
              var decos = []

              for (const [id, user] of Object.entries(usersConnected)){
                if (!user.selection || !user.selection.cursor) { continue; }
                if (user.id == currentUserId || user.selection.cursor <= 1) { continue; }

                const dom = document.createElement('div')

                dom.innerHTML = '<span class="position-relative" style="outline: 1px solid red"><img class="shadow" style="border: 2px solid white; margin: 0; max-width: none;border-radius: 100px; position: absolute; width: 20px;bottom: 100%;left: -10px;" src="' + usersData[user.id].avatar.thumb + '"/></span>'
                dom.style.display = 'inline'
                dom.class = 'tooltip'
                decos.push(Decoration.widget(user.selection.cursor, dom))
              }
              return DecorationSet.create(state.doc, decos);
            }
          }
          view.setProps(props)

        }
      },
    },
    'content.template': function (newVal, oldVal) {
      if (this.contentLoaded) {
        this.updateContent()
      }
    },
    '$route.params.content': function (newVal, oldVal) {
      if (newVal) {
        this.refreshContent()
      }
    },
    'content.accesskey': function (newVal, oldVal) {
      if (this.contentLoaded) {
        this.loadSocket(newVal)
      }
    },
    'content.access_all': function (newVal, oldVal) {
      if (this.contentLoaded) {
        this.willSave = true
        this.updateContentDebounce()
      }
    },
    'content.user_id': function (newVal, oldVal) {
      if (this.contentLoaded) {
        this.willSave = true
        this.updateContentDebounce()
      }
    },
    'content.category_id': function (newVal, oldVal) {
      if (this.contentLoaded) {
        this.willSave = true
        this.updateContentDebounce()
      }
    },
    accesses: {
      deep: true,
      handler: function (val, oldVal) {
        if (JSON.stringify(oldVal) != JSON.stringify(val)) {
          this.updateContentDebounce()
        }
      },
    },
    globalLoading (val) {
      if (val === false) {
        this.loadingFinished = true
        setTimeout(() => {
          this.loadingFinished = false
        }, 2000)
      } else {
        this.loadingFinished = false
      }
    },
    isSidebarRightOpened(val) {
      if (!this.isApp() && this.spreadsheet) {
        this.$nextTick(() => {
          webix.$$(this.spreadsheet).adjust()
        })
      }

    },
    isContentLocked(val) {
      this.editor.setOptions({editable: !val});
    },
    eventWikiEditToken(token_key) {
      if (token_key && token_key != null) {
        let token = this.content.mentions_list.tokens.find((token) => {
          return token.key === token_key
        })
        if (token) {
          this.tokenEdit = token
          this.openModal('wiki-token-edit')
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../src/assets/js/webix/webix/webix.css';
@import '../../../src/assets/js/webix/spreadsheet/spreadsheet.css';

.highlighted,
/deep/ .highlighted {
  transition: box-shadow .1s ease;
  box-shadow: 0 0px 0px 3px #14c1d7;
}

/deep/ .diff-html {
  thead {
    display: none;
  }
  .change-ins, ins, .change-del, del {
    display: inline-block;
    text-decoration: none;
    font-weight: bold;
    padding: 2px 4px;
    border-radius: 4px;
  }
  .change-ins, ins {
    color: green;
    background: rgba(green, .1);
  }
  .change-del, del {
    color: red;
    background: rgba(red, .1);
  }
}
.help-syntax {
  min-width:28px;
  text-align: center;
  display: inline-block;
}
.avatar {
    width: 34px;
    height: 34px;
}
.card-content-inner {
    max-width: 800px;
    margin: 0 auto;
    width: 100%;
}
[contenteditable] {
  &:hover, &:focus {
    cursor: pencil;
    background: rgba(black, .05);
    outline: 5px solid rgba(black, .05);
  }
}
.empty-tokens-forms {
  max-width: 400px;
}
.view-guide-image {
  max-width: 30px;
  transform: scale(1.8);
  margin-top: -5px;
}
#wiki-content-description {
  .editor {
    max-width: 700px;
    margin: 0 auto;
  }
}

.toolbar-table-wrapper {
  position: sticky;
  top: 70px;
  z-index: 2;
  .toolbar-table {
    background: #333;
    position: absolute;
    color: white;
    width: 100%;
    button {
      color: white;
      font-weight: normal;
      &:hover {
        background-color: rgba(#FFF, 0.1);
      }
    }
  }
}
</style>
